import en from './en';
import fr from './fr';

// export translations object
const languages = {
	en,
	fr
};

export default languages;
