/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:d4132745-beee-47bc-aa8c-6e90d38e71b9",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_4ci6KkJs0",
    "aws_user_pools_web_client_id": "4nfbbf0rhjn2u25c5tq2hmpq4d",
    "oauth": {},
    "aws_user_files_s3_bucket": "portfoliospareact68976584e4604b8bab0c3cd5b663d3230657-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
